export const description_en = {
/*    h5: "Technologies are changing the way things are",
    h6: "and making our life more dynamic",
    text:
        "this is a multifunctional interactive platform on which key industrial enterprises of the Republic of Belarus demonstrate their products and production technologies. Enterprises have their own exhibition stands, decorated in accordance with the company brand. The stands show product samples, including 3D models. CleVR combines the usual format of offline industry exhibitions with the functionality and interactivity of social networks. Also, the virtual exhibition includes an extensive set of tools for online conferences, seminars and other forms of business communication.",
    h3: "Make sure of our effectiveness on your own experience",
    button: "VISIT THE EXHIBITION",
    clevr: "cleVR",
    title1: "For visitors",
    text1_1:
        "Visitors can visit the virtual exhibition free of charge and without prior registration ",
    text1_2:
        " using a computer or smartphone connected to the Internet. Any visitor has the opportunity to view the items presented on ",
    text1_3:
        " virtual stands of enterprises and get acquainted with the information posted on them, as well as offer your candidacy and become a participant in the exhibition.",
    text1_4: 'And after filling out a short registration form, the user will be able to start a dialogue with are presentative of the enterprise through an online chat or request a virtual meeting with a certain specialist of the enterprise, as well as get into chat with other visitors.',
    title2: "For exhibitors",
    text2_1:
        "exhibitors have the opportunity to demonstrate their products, equipment and technologies in real time without limiting the exhibition area. Each exhibitor has its own exhibition stand designed   in   accordance   with   the   corporate   style. The company places information and product models at its stand according to the selected placement option.",
    text2_short: 'Exhibitors have the opportunity to demonstrate their products, equipment and technologies in real time without limiting the exhibition area.',
    tabhead: {
        title1: "Service",
        title2: "Tariff",
    },
    tabBody: {
        tab1: "Virtual stand",
        tab2: "Placement in the catalog",
        tab3: "Placement of catalogs and presentations",
        subTab3: "in PDF format",
        tab4: "Posters, banners on the stand",
        tab5: "Helper Bot",
        tab6: "Online chat",
        tab7: "Navigation button",
        subTab7: "(to your own stand)",
        tab8: "Access to training events",
        tab9: "Analytics",
        tab10: "Placement of information on prospective procurement plans",
        tab11: "Agenda",
        subTab11: "(online appointment)",
        tab12: "Video conferencing",
        tab13: "Placement of 3D models of products",
        tab14: "Personal Promotion",
        tab15: "Relocation of Vacancies, search for partners to perform work",
        tab16: "Pass-through banner or promoter",
        subTab16: "(optional)",
    },
    footer: {
        title: 'Cost per month, BYN'
    },
    title3: "Recommendations for Stand Materials",
    text3_1:
        "To get the most out of your company's presence at a virtual exhibition, you should pay special attention to the materials that you post at the stand. For convenience, we decided to leave some small recommendations:",
    text3_2: "Pay attention to the writing of your presentation text",
    text3_3:
        "we recommend making a unique description text. Since the text field is small (up to 2000 characters), please indicate only the most important information. We recommend placement of information about product models, prices, terms of cooperation in brochures in PDF format. They will be conveniently opened for reading directly on our platform, and those brochures and catalogs that the visitor finds useful, he can send for printing or download for saving. ",
    text3_4: "Pay close attention to the visualization of the stand",
    text3_5:
        "High-quality graphic materials, 3D models of products, well-chosen colors of the stand elements will allow you to declare your company's brand and create a respectable image.",
    text3_6: "Pay attention to the greeting text in the online chat",
    text3_7:
        "Even a simple 'Hello! We are glad to see you!' will create a certain mood and the effect of the presence of a consultant at the stand. But you can be creative and address the visitor in an unusual and original way, do not hesitate to bring a smile. Become unusual and unforgettable. ",
    text3_8: "It is very important to respond promptly to all requests from visitors",
    text3_9:
        "Do not disregard such pleasant things as requests for a commercial offer or gratitude, as well as complaints and criticism.",
    text3_10:
        "Attendance at a virtual trade show is an effective marketing tool. Don't miss this great opportunity to promote your business internationally!",
    text4_short: 'Additionally, we offer: various types of photography, as well as design services.',
    moreAboutServices: 'More about services',
    folowers: "Exhibitors",
    pavilions: "Pavilions",
    generalPavilion: "General",
    search: 'Search',
    folowersHead: "this is",
    folowersTitle1: "New effective digital marketing tool",
    folowersText1:
        "Possibility to visit the virtual exhibition at any time.",
    folowersButton1: "VIEW STANDS",
    folowersTitle2:
        "A virtual platform for demonstrating the products of enterprises, their production equipment and technologies",
    folowersButton2: "VIEW STANDS",
    folowersTitle3: "digital cloud platform for B2B communications",
    folowersButton3: "START Communication",
    folowersTitle4: "Virtual Education Center",
    folowersButton4: "START Dialogue",
    accompanyingServices: "Related services",
    photoServices: "Photo services",
    item1: "Subject photography of products",
    item2: "Photography of production facilities and processes",
    item3: "Portrait photography of employees",
    price1: "40 BYN 1 th. (from 10 th.)",
    price2: "90 BYN 1 th. (from 10 th.)",
    price3: "20 BYN 1 th. (from 10 th.)",
    designServices: "Design services",
    item4: "Development of a personal exhibition stand",
    item5: "Design of a virtual stand and demonstration materials in corporate identity exhibitors",
    item6: "Development (refinement) of corporate style and identity",
    price4: "3000 BYN",
    price5: "1500 BYN",
    price6: "Contractual",
    order: "ORDER",
    services: 'Services',*/

    h5: "Virtual",
    h6: "exhibition of enterprises",
    clevr: "CleVR",
    text:
        "this is a multifunctional interactive platform where key industrial enterprises of the Republic of Belarus demonstrate their products and production technologies.",
    AmountHeader1: "5",
    Header1: "pavilions",
    AmountHeader2: "79",
    Header2: "exhibitors",
    AmountHeader3: "> 500",
    Header3: "stands",
    text1: "The exposition of the exhibition consists of five thematic pavilions",
    text2: "79 participating enterprises in the virtual exhibition in the permanent exhibition",
    text3: "500 stands with unique product samples are available for review",
    textBtn1: "Catalog of pavilions",
    textBtn2: "Catalog of exhibitors",
    textBtn3: "Stands constructor",
}