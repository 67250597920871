export const description_ru = {
    h5: "Виртуальная",
    h6: "выставка предприятий",
    clevr: "CleVR",
    text:
        "это многофункциональная интерактивная платформа, на которой ключевые промышленные предприятия Республики Беларусь демонстрируют свою продукцию и технологии производства. ",
    AmountHeader1: "5",
    Header1: "павильонов",
    AmountHeader2: "79",
    Header2: "экспонентов",
    AmountHeader3: "> 500",
    Header3: "стендов",
    text1: "Экспозиция выставки состоит из пяти тематических павильонов",
    text2: "79 предприятий участников в виртуальной выставке в постоянной экспозиции",
    text3: "500 стендов с уникальными образцами продукции доступны для ознакомления",
    textBtn1: "Каталог павильонов",
    textBtn2: "Каталог экспонентов",
    textBtn3: "Конструктор стендов",
}